
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { AxiosRequestConfig } from "axios";
import { VueEditor } from "vue3-editor";
import { event } from "vue-gtag";

interface Course {
  _id: string;
  title: string;
  module: string;
  subject: string;
  createdAt: string;
  updatedAt: string;
  desc: string;
  files: string[];
  photos: string[];
  trimester: string;
  classrooms: string[];
  status: string;
  reviewDate: string;
  reviewNote: string;
  classeRoomNames?: any[];
  teacherName?: string;
}

interface Comment {
  _id: string;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  photo: string;
  text: string;
  user: { _id: string; role: string };
}

export default defineComponent({
  name: "course-info",
  components: { VueEditor },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const user = store.getters.currentUser;
    const fullName = user.employee.firstName + " " + user.employee.lastName;

    const status = ref("notValidated");
    const reason = ref("");

    const courseID = route.params.id;

    const course = ref<Course>({
      subject: "",
      _id: "",
      title: "",
      module: "",
      desc: "",
      createdAt: "",
      updatedAt: "",
      files: [],
      photos: [],
      trimester: "0",
      classrooms: [],
      status: "",
      reviewDate: "",
      reviewNote: "",
      classeRoomNames: [],
      teacherName: "",
    });

    const comments = ref<Comment[]>([]);

    ApiService.get(`/lms/course/${courseID}`)
      .then(({ data }) => {
        course.value = {
          subject: data.subject.name,
          _id: data._id,
          title: data.title,
          module: data.module,
          desc: data.desc,
          createdAt: data.createdAt,
          updatedAt: data.updateAt,
          files: data.resources.filter(
            (f) => !["png", "jpg"].includes(f.split(".")[1])
          ),
          photos: data.resources
            .filter((f) => ["png", "jpg"].includes(f.split(".")[1]))
            .map(
              (p: string) => store.getters.serverConfigUrl.base_url + "/" + p
            ),
          trimester: data.trimester ? data.trimester : "0",
          classrooms: data.classrooms,
          classeRoomNames: data.classeRoomNames,
          status: data.status,
          teacherName:
            data.teacherName.lastName + " " + data.teacherName.firstName,
          reviewDate: data.reviewDate,
          reviewNote: data.reviewNote,
        };
      })
      .catch((e) => {
        console.log(e);
      });

    ApiService.get(`/lms/comment/course/${courseID}`)
      .then(({ data }) => {
        data.forEach((comment: Comment) => {
          comment.photo = comment.photo
            ? store.getters.serverConfigUrl.base_url +
              "/" +
              comment.photo.replace(/\\/, "/")
            : "media/avatars/blank.png";
          comments.value.push(comment);
        });
      })
      .catch((e) => {
        console.log(e);
      });

    const saveReview = () => {
      Swal.fire({
        title:
          status.value == "rejected"
            ? t(`course.confirmRejectCourse`)
            : t(`course.confirmValidationCourse`),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: status.value == "rejected" ? "#ff3333" : "#47BE7D",
        cancelButtonColor: "#3085d6",
        confirmButtonText:
          status.value == "rejected"
            ? t(`course.acceptReject`)
            : t(`course.acceptValidate`),
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.patch("/lms/advisor/reviewCourse/" + course.value._id, {
            status: status.value,
            reviewNote: reason.value,
          } as AxiosRequestConfig)
            .then(() => {
              event("Review course", {
                event_category: "Course",
                event_label: "Course section",
                value: 1,
              });

              Swal.fire(
                status.value == "rejected"
                  ? t(`course.rejected`)
                  : t(`course.validated`),
                status.value == "rejected"
                  ? t("course.rejectedCourseInfo")
                  : t("course.validatedCourseInfo"),
                "success"
              ).then(() => {
                course.value.status = status.value;
                course.value.reviewDate = new Date().toString();
              });
            })
            .catch((e) => console.log(e));
        }
      });
    };

    const getFileIconName = (file: string): string => {
      const type = file.split(".")[1];
      if (
        [
          "pdf",
          "png",
          "jpg",
          "ppt",
          "xls",
          "txt",
          "gif",
          "doc",
          "pptx",
          "xlsx",
          "docx",
          "zip",
          "mkv",
          "avi",
          "mp4",
          "mp3",
        ].includes(type)
      )
        return type;
      return "file";
    };

    const getFileLink = (file: string): string => {
      return (
        store.getters.serverConfigUrl.base_url + "/" + file.replace(/\\/g, "//")
      );
    };

    const getShortFileName = (file: string): string => {
      const t = file.indexOf("--");
      const fileName = file.substring(t == -1 ? 0 : t + 2);
      if (fileName.length > 20)
        return (
          "..." + fileName.substring(fileName.length - 20, fileName.length)
        );
      return fileName;
    };

    const commentText = ref<string>("");

    return {
      t,
      courseID,
      course,
      saveReview,
      moment,
      getFileIconName,
      getFileLink,
      getShortFileName,
      comments,
      fullName,
      commentText,
      status,
      reason,
    };
  },
});
